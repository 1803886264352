
import { Component } from 'vue-property-decorator'
import ListsMixin from '@/modules/insight/mixins/ListsMixin.vue'

@Component({ mixins: [ListsMixin] })
export default class ProductInsightsList extends ListsMixin {
  listDataAction = 'insight/getProductInsights'
  setParamsAction = 'insight/setProductParams'

  orderType: 'Any Warehouse' | 'China' | 'Italy' = 'Any Warehouse'

  get dataKey() {
    if (this.orderType === 'Italy') return 'italianOrderData'
    if (this.orderType === 'China') return 'chineseOrderData'
    return 'orderData'
  }

  get params() {
    return this.$store.state.insight?.productParams || {}
  }

  get products() {
    return this.$store.state.insight ? this.$store.state.insight.products || [] : []
  }

  get headers() {
    const rows = [
      { text: 'Pic', value: 'product.pictures', sortable: false },
      { text: 'Product', value: 'product.title', sortable: false },
      { text: 'Orders', value: 'orderData.orders', sortable: false },
      { text: 'Ad Spend', value: 'orderData.adSpend', sortable: false },
      { text: 'CPA', value: 'orderData.cpa', sortable: false },
      { text: 'AOV', value: 'orderData.aov', sortable: false },
      { text: 'ROAS', value: 'orderData.roas', sortable: false },
      { text: 'COGS', value: 'orderData.cogs', sortable: false },
      { text: 'Shipping', value: 'orderData.shipping', sortable: false },
      { text: 'Revenue', value: 'orderData.revenue', sortable: false },
      { text: 'Refunds', value: 'orderData.refundValue', sortable: false },
      { text: '%Refunds', value: 'orderData.refundPercentage', sortable: false },
      { text: 'Profit', value: 'orderData.profit', sortable: false },
      { text: 'Margin', value: 'orderData.margin', sortable: false },
      { text: 'Pcs Sold', value: 'orderData.quantity', sortable: false }
    ]
    if (this.dataKey === 'italianOrderData') {
      rows.push({ text: 'Delivery Rate', value: 'orderData.deliveryRate', sortable: false })
      rows.push({ text: 'Real Revenue', value: 'orderData.realRevenue', sortable: false })
      rows.push({ text: 'Real Margin', value: 'orderData.realMargin', sortable: false })
      rows.push({ text: 'Real Profit', value: 'orderData.realProfit', sortable: false })
    }
    return rows
  }
}
