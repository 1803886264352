
import { Component, Prop } from 'vue-property-decorator'
import { debounce } from 'lodash'
import VueStrong from '@/VueStrong'

@Component
export default class ListsMixin extends VueStrong {
  @Prop({ default: false }) loading: boolean

  filtersType = '' // 'campaign' | 'adset' | 'ad'
  debounceGetData = debounce(this.listData, 500)
  listDataAction = '' // 'getCampaigns' | 'getAdSets' | 'getAds'
  setParamsAction = '' // 'setCampaignParams' | 'setAdSetParams' | 'setAdParams'

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount || '' : ''
  }

  get params(): any {
    throw new Error(`you need to override the get params ${this.$options.name}`)
  }

  get pageCount() {
    return Math.ceil(this.params.serverItemsLength / this.params.itemsPerPage) || 0
  }

  created() {
    this.listData()
  }

  async listData() {
    try {
      this.$emit('setLoading', true)
      if (this.selectedAdAccount) await this.$store.dispatch(this.listDataAction)
    } catch (error) {
      this.$emit('errorNotification', true)
    } finally {
      this.$emit('setLoading', false)
    }
  }

  async updateParams(newParams: any): Promise<void> {
    this.$store.dispatch(this.setParamsAction, newParams)
    await this.debounceGetData()
  }

  async updatePage(page: number): Promise<void> {
    await this.updateParams({ ...this.params, page })
  }

  async updateItemsPerPage(itemsPerPage: number): Promise<void> {
    await this.updateParams({ ...this.params, itemsPerPage, page: 1 })
  }
}
