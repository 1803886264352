
import { Component } from 'vue-property-decorator'
import FiltersMixin from '@/modules/insight/mixins/FiltersMixin.vue'
import moment from 'moment'

@Component({ mixins: [FiltersMixin] })
export default class ProductInsightsFilters extends FiltersMixin {
  date = ''
  dates = []
  modal = false

  paramsField = 'productParams'
  setFilterAction = 'insight/setProductParams'
  getAction = 'insight/getProductInsights'

  initialParams = { start: this.formatDate(moment().subtract(7, 'day')), end: this.formatDate(moment()) }
}
